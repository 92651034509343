import './index.scss';
import SnakeFeverTN from '../../../../assets/images/SnakeFever/Snakefever_game.png'
import SnakeFever1 from '../../../../assets/images/SnakeFever/Snakefever.png'
import SnakeFever2 from '../../../../assets/images/SnakeFever/Snakefever_tutorial.png'



function Rtopia(props){

    return props.trigger ? (
        <div className="snakefever-info">
            <div className="infoText">
            <h3>
                SnakeFever
            </h3>
            <p>
            As a part of a course on user interface programming, our project team developed a web-based game through React using the p5 library for animation. In this 2-player game, the objective is to make your opponent crash into the tail of your "snake". Whoever survives the longest wins. In terms of the programming, a major challenge was to create a dynamic collision detection based on the size of each individual snake. This was one of the first times I had used React and a great learning opportunity for how passing props between components functioned.
            </p>
            </div>
            <br/>
            <div className="infoContent">
            <img className='info-img' src={SnakeFever1} />
            <p className='img-text'>Inital starting screen</p>
            <img className='info-img' src={SnakeFeverTN} />
            <p className='img-text'>The interface of the actual game, with a scoreboard to the right and a navbar on top</p>
            <img className='info-img' src={SnakeFever2} />
            <p className='img-text'>An interactive tutorial menu, where players can click on different powerups to see how they are used</p>
            </div>
        </div>
    )
    : (
        ""
    );
}


export default Rtopia