import './index.scss';
import LogoArt from '../../../assets/images/logoArt.png'

const Logo = () => {
    return (
        <div className='logo-container'>
            <img className='image-logo' src={LogoArt} alt="S"/>
        </div>
    )
}

export default Logo