import Loader from 'react-loaders';
import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const refForm = useRef()

    useEffect(() => {
        setTimeout(()=> {
            return setLetterClass('text-animate-hover')
        }, 2000)
    }, [])

    const sendEmail = (e) => {
        e.preventDefault()
        emailjs.sendForm(
            'service_0rnta8a',
            'template_48mx4lq',
            refForm.current,
            'SSEXKuPuuvR5ho5_3'
        )
        .then(
            () => {
                alert('Message successfully sent')
                window.location.reload(false)
            },
            () => {
                alert('Failed to send the message')
            }
        )
    }

    return (
        <>
        <div className='container contact-page'>
            <div className='text-zone'>
                <h1>
                    <AnimatedLetters
                    letterClass={letterClass}
                    strArray={['C','o','n','t','a','c','t',' ','m','e']}
                    idx={7}
                    />
                </h1>
                <div className="contactText">
                <p>
                I am currently interested in opportunities within both UX and frontend development, as well as something unrelated if the fit is right! If you think I might be a good fit for a position, please reach out to me.
                </p>
                </div>
                <div className='contact-form'>
                    <form ref={refForm} onSubmit={sendEmail}>
                        <ul>
                            <li className='half'>
                                <input type="text" name="name" placeholder='Name' required/>
                            </li>
                            <li className="half">
                                <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                required
                                />
                            </li>
                            <li>
                                <input placeholder="Subject" type="text" name="subject" required/>
                            </li>
                            <li>
                                <textarea placeholder="Message" name="message" required></textarea>
                            </li>
                            <li>
                                <input type="submit" className='flat-button' value="SEND"/>
                            </li>

                        </ul>
                    </form>
                </div>
            </div>
            </div>
        <Loader type="ball-clip-rotate"/>
        </>
    )
}

export default Contact