import './index.scss';
import AnimatedLetters from '../AnimatedLetters';
import { useEffect, useState } from 'react';
import Loader from 'react-loaders';
import Profile from '../../assets/images/Profile.jpg'
import Project from './Project';
import CytivaTN from '../../assets/images/Cytiva/Cytiva_TN.jpg'
import UBGTN from '../../assets/images/UBG/UBGTN.png'
import SnakeFeverTN from '../../assets/images/SnakeFever/Snakefever_game.png'
import RtopiaTN from '../../assets/images/Rtopia/RtopiaTN.png'
import WebsiteTN from '../../assets/images/Website/WebsiteTN.png'
import Cytiva from './ProjectContent/Cytiva'
import UBG from './ProjectContent/UBG'
import Rtopia from './ProjectContent/Rtopia'
import SnakeFever from './ProjectContent/SnakeFever'
import Website from './ProjectContent/Website'
import ReturnButton from './ReturnButton'

const About = () => {

    const [letterClass, setLetterClass] = useState('text-animate')
    const [closeButton, setCloseButton] = useState(false)
    const [cytiva, setCytiva] = useState(false)
    const [uppsala, setUppsala] = useState(false)
    const [recyclotopia, setRecyclotopia] = useState(false)
    const [snakeFever, setSnakeFever] = useState(false)
    const [website, setWebsite] = useState(false)

    useEffect(() => {
        setTimeout(()=> {
            return setLetterClass('text-animate-hover')
        }, 2000)
    }, [])

    return (
        <>
        <div className='container about-page'>
            <div className="text-zone">
            <img className='profile-img' src={Profile} alt="P"/>
            <h1>

                <AnimatedLetters
                letterClass={letterClass}
                strArray={['A','b','o','u','t',' ', 'm','e']}
                idx={7}
                />
            </h1>
            <div className="aboutText">
            <p>
            I am a UX-designer / frontend-developer in the early stages of my career as I recently graduated from a master program in human-computer interaction at Uppsala University. The program focused mainly on UX-related practices with some basic level of programming. I also hold a bachelor’s degree in system development, which featured a higher level of focus on programming in different forms.
            </p>
            <p>
            In terms of programming, I am mostly experienced in web development through JavaScript, HTML and CSS using React, as well as C#. When developing visual concepts I use Figma, which I have used for all my design projects. Other relevant experience I have is working with GitHub and Docker.
            </p>
            <p>
            As an employee I do my best work in a collaborative environment with great co-workers, but also with a heavy focus on personal competence and contribution. I really enjoy problem solving in all different forms, which is one of the reasons I really gravitated toward UX / programming. I think the process of learning how users interact with software, and to figure out how that interaction can be improved in the best possible way is very engaging. 
            </p>
            </div>
            </div>
            <div className='project-zone' id="pZone">
            <h1 className="project-header" id="projectHeader">
                <AnimatedLetters
                letterClass={letterClass}
                strArray={['P','r','o','j','e','c','t','s']}
                idx={7}
                />
                <ReturnButton
                    trigger={closeButton}
                    setTrigger={setCloseButton}
                    setCytiva={setCytiva}
                    setUppsala={setUppsala}
                    setRecyclotopia={setRecyclotopia}
                    setSnakeFever={setSnakeFever}
                    setWebsite={setWebsite}
                />
            </h1>
            <div className="project-info-zone">

                <div className="infoWrapper" id="pInfo">
                    <Cytiva trigger={cytiva} setTrigger={setCytiva}/>
                    <UBG trigger={uppsala} setTrigger={setUppsala}/>
                    <Rtopia trigger={recyclotopia} setTrigger={setRecyclotopia}/>
                    <SnakeFever trigger={snakeFever} setTrigger={setSnakeFever}/>
                    <Website trigger={website} setTrigger={setWebsite}/>
                </div>
            </div>
            <div className="projects" id="projectCards">
                <Project 
                    title={'Bioreactor Software'} 
                    description={'Increasing the usability of complex life science software'} 
                    thumbnail={CytivaTN}
                    setTrigger={setCytiva}
                    setReturnTrigger={setCloseButton}
                    category={'UX'}
                />
                <Project 
                    title={'Bicycle Garage'} 
                    description={'A new design concept for the structure of Uppsala bicycle garage'} 
                    thumbnail={UBGTN}
                    setTrigger={setUppsala}
                    setReturnTrigger={setCloseButton}
                    category={'UX'}
                />
                <Project 
                    title={'Recyclotopia'} 
                    description={'Helping international students learn how to recycle better'} 
                    thumbnail={RtopiaTN}
                    setTrigger={setRecyclotopia}
                    setReturnTrigger={setCloseButton}
                    category={'UX'}
                />
                <Project 
                    title={'SnakeFever'} 
                    description={'A browser game developed with React'} 
                    thumbnail={SnakeFeverTN}
                    setTrigger={setSnakeFever}
                    setReturnTrigger={setCloseButton}
                    category={'Dev'}
                />
                <Project 
                    title={'This portfolio'} 
                    description={'The process of creating this website'} 
                    thumbnail={WebsiteTN}
                    setTrigger={setWebsite}
                    setReturnTrigger={setCloseButton}
                    category={'Dev'}
                />
            </div>
            </div>
        </div>
        <Loader type="ball-clip-rotate"/>
        </>
    )
}

export default About