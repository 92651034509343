import './index.scss';
import CytivaTN from '../../../../assets/images/Cytiva/Cytiva_TN.jpg'
import CytivaN1 from '../../../../assets/images/Cytiva/Cytiva_N1.png'
import CytivaN2 from '../../../../assets/images/Cytiva/Cytiva_N2.png'
import CytivaNP1 from '../../../../assets/images/Cytiva/ProcessPicture_Censored.png'
import CytivaNP2 from '../../../../assets/images/Cytiva/Zoomed_in_View_Censored.png'
import CytivaOP1 from '../../../../assets/images/Cytiva/Temp_Censored.png'
import CytivaOP2 from '../../../../assets/images/Cytiva/PID_censored.png'


function Cytiva(props){
    
    return props.trigger ? (
        <div className="cytiva-info">
            <div className="infoText">
            <h3>
                Increased usability for complex bioreactor software
            </h3>
            <p>
            As part of my master thesis in collaboration with Cytiva and Testa center, the given task was to improve the usability of an interface to a complex bioreactor system with a wide range of advanced control options and functionality. The system has a frequent influx of novice users who are supposed to learn and use the system to a basic extent under the supervision of more experienced users. This created some usability issues in terms of the need for experienced users to have a high degree of control, while novice users also need to be able to gain an understanding of the software to be able to run projects on their own.   
            </p>
            <br/>
            <p>
            Users of the software were interviewed to clarify the user needs of the different groups, which resulted in three different areas of improvement:<br/><br/>  &#x2022; A low level of visual indication <br/> &#x2022; The need for a higher degree of manual control <br/> &#x2022; Give more visual priority to basic features in the software. <br/> <br/>A new design was developed and turned into a functional prototype, which was tested through user evaluation and iterated based on user feedback. The process resulted in a somewhat novel feature through a zoom-in function as an adaptable view for more advanced options. Some of the views have been somewhat censored due to a contractual obligation with Cytiva.   
            </p>
            </div>
            <br/>
            <div className="infoContent">
            <img className='info-img' src={CytivaN1} alt="tn"/>
            <p className="img-text">An initial view in the new design, which has improved indication of what is selected, which values are reaching problematic levels, etc.</p>
            <img className='info-img' src={CytivaOP1} alt="tn"/>
            <p className="img-text">A heavily censored view of the old interface, which has a very "old 90's" feel to it.</p>
            <img className='info-img' src={CytivaOP2} alt="tn"/>
            <p className="img-text">A view of the more detailed control options in the process picture view for the old interface, which can be quite confusing for new users</p>
            <img className='info-img' src={CytivaN2} alt="tn"/>
            <p className="img-text">Cleaning menu for the new design, showing active and completed processes, as well as maintaining an overview of reactor values.</p>
            <img className='info-img' src={CytivaNP1} alt="tn"/>
            <p className="img-text">The new version of the more advanced process picture, which has been somewhat simplified in an initial view with some more specific information filtered out. Certain areas in the menu can be highlighted, which brings up additional options, as well as a zoom button to give a more detailed view.</p>
            <img className='info-img' src={CytivaNP2} alt="tn"/>
            <p className="img-text">A zoomed in view of an area in the process picture, which visualizes a fully detailed overview of all the different control options for the reactor flow.</p>
            </div>
        </div>
    )
    : (
        ""
    );
}


export default Cytiva