import './index.scss';
import UBGTN from '../../../../assets/images/UBG/UBGTN.png'
import UBG1 from '../../../../assets/images/UBG/UBG1.png'
import UBG2 from '../../../../assets/images/UBG/UBG2.png'
import UBG3 from '../../../../assets/images/UBG/UBG3.png'
import UBG4 from '../../../../assets/images/UBG/Persona.png'


function UBG(props){

    return props.trigger ? (
        <div className="ubg-info">
            <div className="infoText">
            <h3>
                A new design concept for Uppsala bicycle garage
            </h3>
            <p>
            The municipal bicycle garage in Uppsala currently have a lot of issues with a way for users to gain an estimation of how many spots are available in the garage, and for how long they can use it. There is an app, but it is not very functional in terms of giving a correct estimate. Our task was to conduct user research and to create a design proposal adressing the described problem. One of the major user concerns was that there was no way for users to get a guaranteed spot. Our solution was based around a rental process, where users could sign up and rent a specific spot. 
            </p>
            </div>
            <br/>
            <div className="infoContent">
            <img className='UGB1' src={UBG1} />
            <p className="img-text">An example of the current app for the bicycle garage, which often displays a very inaccurate number of available spots.</p>
            <img className='UGB2' src={UBG4} />
            <p className="img-text">Based on our user research, this was the persona we used as guidance during the design</p>
            <img className='UGBTN' src={UBGTN} />
            <p className="img-text">Onboarding process for the new design solution</p>
            <img className='UGB-2' src={UBG2} />
            <p className="img-text">The proposed rental process, which is performed by navigating available spots in the garage through an interactive map</p>
            <img className='UGB-2' src={UBG3} />
            <p className="img-text">Views of a user completing their subscription, and the base view for an active subscription showing a QR-code to enter the garage.</p>
            </div>
        </div>
    )
    : (
        ""
    );
}


export default UBG