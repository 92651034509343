import './index.scss';

function ReturnButton(props){
    
    return props.trigger ? (
        <div className="closeButtonWrapper">
            <button className="close-button" 
            onClick={() => {
                props.setTrigger(false);
                props.setCytiva(false);
                props.setUppsala(false);
                props.setRecyclotopia(false);
                props.setSnakeFever(false);
                props.setWebsite(false);
                document.getElementById("pInfo").style.zIndex = 0;
                document.getElementById("projectCards").style.visibility = "visible";
            }}
            ><i class="back-arrow"></i></button>
        </div>
    )
    : (
        ""
    );
}

export default ReturnButton