import './index.scss';
import LogoTitle from '../../assets/images/logo-e.png'
import {Link, NavLink} from 'react-router-dom'
import { useEffect, useState } from 'react';
import AnimatedLetters from '../AnimatedLetters';
import Logo from './Logo';
import Loader from 'react-loaders'

const Home = () => {
    const [letterClass, setLetterClass] = useState('text-animate')
    const nameArray = ['r','i','k']

    useEffect(() => {
        setTimeout(()=> {
            return setLetterClass('text-animate-hover')
        }, 4000)
    }, [])


    return (
        <>
        <div className="container home-page">
            <div className="text-zone">
                <h1>
                    <span className={letterClass}>H</span>
                    <span className={`${letterClass} _12`}>i,</span>
                    <br />
                    <span className={`${letterClass} _13`}>I</span>
                    <span className={`${letterClass} _14`}>'m</span>
                <img src={LogoTitle} alt="developer" />
                <AnimatedLetters letterClass={letterClass}
                strArray={nameArray}
                idx={15}/> </h1>
                <br/>
                <h2>UX-Designer / Frontend Developer</h2>
                <div className="mobileImg">
                <Logo/>
                </div>
                <Link to="/contact" className='flat-button'>CONTACT ME</Link>
                <Link to="/about" className='flat-button'>VIEW PROJECTS</Link>
            </div>
            <div className="logoContainer">
            <Logo/>
            </div>
        </div>
        <Loader type="ball-clip-rotate"/>
        </>
    );
}
export default Home