import './index.scss';
const Project = ({title, description, thumbnail, setTrigger, setReturnTrigger, category}) => {


    const handleClick = (e) => {
        e.preventDefault();
        setTrigger(true);
        setReturnTrigger(true);
        document.getElementById("pInfo").style.zIndex = 2;
        document.getElementById("projectCards").style.visibility = "hidden";
      }
    
    var imgSrc = "";
    var categoryBground = "";

    if (category == 'UX')
    {
        imgSrc = "textRed";
        categoryBground = "bGroundRed"
    }
    else if (category == 'Dev')
    {
        imgSrc = "textBlue";
        categoryBground = "bGroundBlue"
    }

    return (
        <div className="project" onClick={handleClick}>
            <div className="pHeader">
                <div className={categoryBground}>
                    <p className={imgSrc}>{category}</p>
                </div>
                <div className="test">
            {title}
                </div>
            </div>
            <img className='thumbnail-img' src={thumbnail} alt="tn"/>
            <div className="borderDesc">
            <p>{description}</p>
            </div>
        </div>
    )
};

export default Project