import './index.scss';
import WebsiteTN from '../../../../assets/images/Website/WebsiteTN.png'
import react from '../../../../assets/images/Website/react.png'
import Coolors from '../../../../assets/images/Website/Coolors.png'

function Website(props){

    return props.trigger ? (
        <div className="website-info">
            <div className="infoText">
            <h3>
                Developing a portfolio with React
            </h3>
            <p>
            I wanted to develop a professional portfolio with a way of viewing information about the different projects I have been a part of, and to learn more about web development in the process. I already had experience using React as a framework, but for more basic projects and heavily reliant on existing libraries. For this website, I wanted to start out following a basic template and later develop new features on my own.
            </p>
            </div>
            <br/>
            <div className="infoContent">
            <img className='codeImg' src={WebsiteTN} />
            <p className="img-text">In this project I'm using Fort Awesome free icons, different libraries such as animate.css for animation and emailJS for the contact form</p>
            <img className='reactImg' src={react} />
            <p className="img-text">The website was developed with React, which is the framework I am most experienced in</p>
            <img className='reactImg' src={Coolors} />
            <p className="img-text">To create nice-looking color schemes, I like to use coolors to generate ideas</p>
            </div>
        </div>
    )
    : (
        ""
    );
}

export default Website