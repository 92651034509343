import './index.scss';
import RtopiaTN from '../../../../assets/images/Rtopia/RtopiaTN.png'
import Rtopia1 from '../../../../assets/images/Rtopia/Rtopia1.png'
import Rtopia2 from '../../../../assets/images/Rtopia/Rtopia2.png'



function Rtopia(props){

    return props.trigger ? (
        <div className="rtopia-info">
            <div className="infoText">
            <h3>
                Recyclotopia - an app to help international students recycle better
            </h3>
            <p>
            A current problem among international students is that many of them do not know how to recycle in Sweden. Some of the concepts can be quite confusing, especially when you don’t speak the language. To solve this problem, our project team developed a functioning prototype of an app intended to help international students learn how to recycle correctly. 
            </p>
            <br/>
            <p>
            Our user research showed that a current usability issue for students is a difficulty to account for more “rare” types of waste, which might need to be put in a special bin. The design proposes a solution to this problem through scanning the barcode of a given item, which displays information about the recycling process as well as the nearest eligible recycling station for that specific item.    
            </p>
            </div>
            <br/>
            <div className="infoContent">
            <img className='info-img' src={Rtopia1} />
            <p className='img-text'>Initial onboarding process of the app.</p>
            <img className='info-img' src={Rtopia2} />
            <p className='img-text'>Different menu options in the app, such as the primary way of finding an item through either manual search or barcode scan. Additional options include a map of nearby recycling stations, and and information page regarding general recycling knowledge.</p>
            <img className='info-img' src={RtopiaTN} />
            <p className='img-text'>An example of the user flow for scanning an item, and getting information of the nearest point where that item is able to be recycled.</p>
            </div>
        </div>
    )
    : (
        ""
    );
}


export default Rtopia